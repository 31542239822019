import { render, staticRenderFns } from "./ClassifiesManage.vue?vue&type=template&id=5d4b695a&scoped=true"
import script from "./ClassifiesManage.vue?vue&type=script&lang=js"
export * from "./ClassifiesManage.vue?vue&type=script&lang=js"
import style0 from "./ClassifiesManage.vue?vue&type=style&index=0&id=5d4b695a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d4b695a",
  null
  
)

export default component.exports